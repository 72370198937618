import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line

class Post extends Component {
  render() {
    this.props.pageContext.backgroundColor =
      this.props.data.wpPost.PageBackground.pageBackgroundColour
    this.props.pageContext.date = this.props.data.wpPost.date
    this.props.pageContext.excerpt = this.props.data.wpPost.excerpt
    this.props.pageContext.featuredImage =
      this.props.data.wpPost?.featuredImage?.node
    this.props.pageContext.title = this.props.data.wpPost.title

    const blocks = buildBlocks(
      this.props.data.wpPost.blocks,
      {},
      this.props.pageContext,
      this.props.data.wpPost.id
    )

    return (
      <Layout
        meta={this.props.data.wpPost.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPost.title}
        slug={this.props.data.wpPost.slug}
        background={this.props.data.wpPost.PageBackground.pageBackgroundColour}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM")
      excerpt
      featuredImage {
        node {
          altText
          publicUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1088
                aspectRatio: 1.67
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      PageBackground {
        pageBackgroundColour
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`

export default Post
